// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-post-blog-post-jsx": () => import("./../../../src/components/BlogPost/BlogPost.jsx" /* webpackChunkName: "component---src-components-blog-post-blog-post-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-get-involved-jsx": () => import("./../../../src/pages/get-involved.jsx" /* webpackChunkName: "component---src-pages-get-involved-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-forecasting-jsx": () => import("./../../../src/pages/projects/forecasting.jsx" /* webpackChunkName: "component---src-pages-projects-forecasting-jsx" */),
  "component---src-pages-projects-index-jsx": () => import("./../../../src/pages/projects/index.jsx" /* webpackChunkName: "component---src-pages-projects-index-jsx" */),
  "component---src-pages-projects-nowcasting-jsx": () => import("./../../../src/pages/projects/nowcasting.jsx" /* webpackChunkName: "component---src-pages-projects-nowcasting-jsx" */),
  "component---src-pages-projects-open-energy-data-jsx": () => import("./../../../src/pages/projects/open-energy-data.jsx" /* webpackChunkName: "component---src-pages-projects-open-energy-data-jsx" */),
  "component---src-pages-projects-pv-mapping-jsx": () => import("./../../../src/pages/projects/pv-mapping.jsx" /* webpackChunkName: "component---src-pages-projects-pv-mapping-jsx" */)
}

